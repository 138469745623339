import type { HTMLAttributes } from "vue";
import {
  FilteredMaterialsByCategoriesI,
  IProductConfigurationResult,
  MaterialData,
} from "~/types/api/materials.types";
import { IProductDetailsMap, VariantResponse } from "~/types/api/product.types";

export const AuthDrawerEnum = {
  LOGIN: "login",
  REGISTER: "register",
  FORGOT: "forgot",
  RECOVERY: "recovery",
} as const;

export type AuthDrawerType =
  (typeof AuthDrawerEnum)[keyof typeof AuthDrawerEnum];

export interface UiFilterToggleInterface {
  value: string;
  title: string;
  icon?: string;
}

export interface UiFilterTab {
  label: string;
  slug: string;
  refs?: string[];
  advanced?: boolean;
}

export interface IUiFilterTag {
  id: number | string;
  category_title: string;
  type: string;
  category_id: number | string;
  category_sort_order: number;
  title: string;
  value: string;
}

export interface ITranslation {
  language_id: number;
  title: string;
}

export interface IProductMaterialFilterEvent {
  slug: string;
  item?: MaterialData;
}

export interface IProductMaterialFilterProps {
  title: string;
  selectedItem: MaterialData | null;
  block: FilteredMaterialsByCategoriesI;
  labelClass?: HTMLAttributes["class"];
  hideResultNearLabel?: boolean;
}

export interface IProductPdpInfoProps {
  seoTitle?: string;
  productTitle: string;
  currentConfiguration: VariantResponse | null;
  inWishlist: boolean;
  discountValue: number;
  productPrice: number;
  finalProductPrice: number;
  filtersPdpManyInOne: boolean;
  filteredMaterialsByCategories: FilteredMaterialsByCategoriesI[];
  result: IProductConfigurationResult;
  additionalOptions: IProductConfigurationResult;
  addToCartLoading: boolean;
  detailsMapedData?: IProductDetailsMap[];
  productDescription?: string;
  shippingReturnsAccordionData?: {
    title: string;
    content: string;
  };
  hasUnavailableMaterial?: boolean;
}
